<template>
  <div>
    <q-stepper v-model="step" ref="stepper" color="primary" animated>
      <q-step :name="1" title="2FA" :done="step > 1"
        >Two Factor Authentication requires that you enter a verification code from your mobile
        device every time you log in to the app. This prevents someone from accessing sensitive
        client information if they get your password. Download the Google Authenticator app on your
        mobile device to continue.</q-step
      >

      <q-step :name="2" title="Scan QR Code" :done="step > 2">
        <ApolloMutation @done="showQrCode" :mutation="getTwoFactorCodeMutation">
          <template v-slot="{ mutate, loading, error }">
            Click "Get QR Code", and then scan the displayed code with your phone.
            <div v-if="qrCode && !loading" class="row justify-center">
              <img :src="qrCode" alt="QR Code" />
            </div>
            <q-banner class="bg-red q-ma-sm" rounded v-if="error"
              >Error: {{ error.message.replace('GraphQL error: ', '') }}</q-banner
            >
            <div class="row justify-center q-pa-md">
              <q-btn color="primary" :loading="loading" @click="mutate()">Get QR Code</q-btn>
            </div>
          </template>
        </ApolloMutation>
      </q-step>

      <q-step :name="3" title="Verify Code" :done="step > 3">
        Please enter the code displayed in the Google Authenticator App:
        <div class="row justify-center">
          <q-input style="max-width: 100px" v-model="totpCode" type="number" label="Code" />
        </div>
        <ApolloMutation
          @done="step++"
          :mutation="verifyTwoFactorMutation"
          :variables="{ token: totpCode }"
        >
          <template v-slot="{ mutate, loading, error }">
            <q-banner class="bg-red q-ma-sm" rounded v-if="error"
              >Error: {{ error.message.replace('GraphQL error: ', '') }}</q-banner
            >
            <div class="row justify-center q-pa-md">
              <q-btn :disable="!totpCode" color="primary" :loading="loading" @click="mutate()"
                >Verify</q-btn
              >
            </div>
          </template>
        </ApolloMutation>
      </q-step>

      <q-step class="text-center" :name="4" title="Done">
        <q-avatar color="orange" size="84px" icon="vpn_key" />
        <p class="text-h5 q-mt-md">Your Account Is Now Secure!</p>
        <q-btn class="q-mt-lg" color="primary" v-close-popup @click="$emit('complete')"
          >Finish</q-btn
        >
      </q-step>

      <template v-slot:navigation>
        <q-stepper-navigation>
          <q-btn v-if="step < 4 && !preventCancel" class="q-mr-sm" v-close-popup>Cancel</q-btn>
          <q-btn
            v-if="step > 1 && step < 4"
            flat
            color="primary"
            @click="$refs.stepper.previous()"
            label="Back"
            class="q-ml-sm"
          />
          <q-btn v-if="step < 3" @click="$refs.stepper.next()" color="primary" label="Continue" />
        </q-stepper-navigation>
      </template>
    </q-stepper>
  </div>
</template>

<script src="./TwoFactorEnable.ts"></script>

<style></style>
