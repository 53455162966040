import gql from 'graphql-tag'
import type { usersSearch } from 'schema-types/usersSearch'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AssignmentSelector',
  props: ['modelValue'],
  data() {
    return {
      assignmentUsers: [] as Array<{ label: string; value: string }>,
    }
  },
  emits: ['update:modelValue'],
  methods: {
    searchUsers(val: string, update: (updateFn: () => void) => void) {
      this.$apollo
        .query<usersSearch>({
          query: gql`
            query usersSearch($search: String, $page: Int!, $perPage: Int!) {
              users(search: $search, page: $page, perPage: $perPage) {
                data {
                  id
                  name
                  email
                }
                totalCount
                totalCount
              }
            }
          `,
          variables: {
            search: val,
            page: 1,
            perPage: 1000000,
          },
        })
        .then(({ data }: any) => {
          update(() => {
            this.assignmentUsers = data.users?.data?.map((user: any) => ({
              label: user.name,
              value: user.id,
            }))
          })
        })
    },
  },
})
