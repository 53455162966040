import { defineComponent } from 'vue'
import gql from 'graphql-tag'

export default defineComponent({
  name: 'RequestPasswordResetLink',
  data() {
    return {
      required: (val?: string) => (val && val.length > 0) || 'This field is required',
      emailInput: '',
      requestingLink: false,
    }
  },
  methods: {
    requestLink() {
      this.requestingLink = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation requestPasswordResetLink($email: String!) {
              requestPasswordResetLink(email: $email) {
                expires
              }
            }
          `,
          variables: {
            email: this.emailInput,
          },
        })
        .then(() => {
          this.requestingLink = false
          this.$q.dialog({
            title: 'Link Sent',
            html: true,
            message:
              `Check for an email from "passwordreset@${process.env.VUE_APP_NAME}.app".` +
              ' Follow the instructions in the email to reset your password.' +
              '<br/><br/>Check your Spam folder and move the email to your inbox. The link will not work while the email is in your Spam folder.',
          })
        })
        .catch((err: Error) => {
          this.requestingLink = false
          this.$q.dialog({
            title: 'Error',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })
    },
  },
})
