import gql from 'graphql-tag'

export const AllClientData = gql`
  fragment AllClientData on Client {
    id
    status
    firstName
    lastName
    middleName
    nickname
    maidenName
    dateOfBirth
    Client_alternateFirstNames {
      value
    }
    Client_alternateLastNames {
      value
    }
    email
    Client_emails {
      value
    }
    updatedAt
    Client_phoneNumbers {
      value
    }
    addresses {
      state
      city
      zipCode
      streetAddress
    }
    tempEmail
    tempEmailPassword
    department
    age
    otherInfo
    includeStreetSuffixInQuickSearch
    ClientType
    Client_spouses {
      value
    }
    sendReportsTo
    stripeAccount
  }
`
export const StatusCounts = gql`
  fragment StatusCounts on Client {
    statusCounts {
      unknown
      noExist
      exist
      removalRequested
      removed
    }
  }
`
