import { siteCategoriesQuery } from '@/queries'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SiteCategoriesEditor',
  data() {
    return {
      showDialog: false,
      values: {} as { [index: string]: string },
      siteCategories: [] as Array<{ id: string; name: string }>,
    }
  },
  apollo: {
    siteCategories: {
      query: siteCategoriesQuery,
      result({ data }) {
        const values: { [index: string]: string } = {}
        data.siteCategories.forEach(({ id, name }: { id: string; name: string }) => {
          values[id] = name
        })
        this.values = values
      },
    },
  },
  methods: {
    createNew() {
      this.$apollo.mutate({
        mutation: gql`
          mutation createSiteCategory {
            createSiteCategory(name: "New Category") {
              id
              name
            }
          }
        `,
        refetchQueries: ['siteCategories'],
      })
    },
    async saveChanges() {
      for (const id in this.values) {
        if (this.values[id] !== this.siteCategories.find(({ id: sId }) => sId === id)?.name) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation updateSiteCategory($id: String!, $name: String!) {
                updateSiteCategory(id: $id, name: $name) {
                  name
                  id
                }
              }
            `,
            variables: {
              id,
              name: this.values[id],
            },
          })
        }
      }
      this.$apollo.queries.siteCategories.refresh()
    },
  },
})
