<template>
  <div>
    <div v-if="queryError" class="q-pa-lg error apollo">
      <q-banner rounded class="bg-red-2">
        <template v-slot:avatar> <q-icon name="error" color="red" /> </template>An error occurred
        while trying to fetch instruction documents:
        <span class="q-pl-sm">{{ queryError }}</span>
      </q-banner>
    </div>

    <q-dialog v-model="editDialog">
      <q-card class="q-pa-md">
        <q-card-section>
          <div class="text-h6">Instruction Document Editor</div>
        </q-card-section>

        <q-card-section>
          <q-input label="Name" v-model="instructionDocumentData.name" />

          <q-input label="Url" v-model="instructionDocumentData.url" />

          <div class="row justify-center">
            <q-btn
              :loading="saving"
              @click="saveInstructionDocument"
              color="primary"
              class="q-ma-md"
              >Save</q-btn
            >
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-table
      class="q-ma-sm"
      title="Instruction Documents"
      :rows="instructionDocuments"
      :columns="table.columns"
      row-key="name"
      :loading="$apollo.queries.instructionDocuments.loading"
      v-model:pagination="table.pagination"
      :rows-per-page-options="[50, 100, 500, 1000, 0]"
    >
      <template v-slot:top-right>
        <q-btn
          @click="
            () => {
              instructionDocumentData = { id: '', name: '', url: '' }
              editDialog = true
            }
          "
          color="primary"
          outline
          >Create New</q-btn
        >

        <q-btn
          @click="$apollo.queries.instructionDocuments.refetch().catch()"
          :loading="$apollo.queries.instructionDocuments.loading"
          icon="refresh"
          flat
          round
        />
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td>
            <q-btn
              @click="
                () => {
                  instructionDocumentData = { ...props.row }
                  editDialog = true
                }
              "
              icon="edit"
              flat
              round
              class="q-ml-sm"
            ></q-btn>
          </q-td>

          <q-td v-for="(columnData, name) of filterColumnData(props.row)" :key="name">{{
            columnData
          }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script src="./InstructionDocuments.ts"></script>

<style></style>
