import ClientEditor from '@/components/ClientEditor/ClientEditor.vue'
import { AllClientData } from '@/fragments'
import { currentUserQuery } from '@/queries'
import type { ReportType } from '@/utils'
import {
    User_authLevel,
    displayStatuses,
    emailSites,
    formatForDisplay,
    generateReport,
    hasAuthLevel,
    openSiteUrl,
    reportMessages,
} from '@/utils'
import gql from 'graphql-tag'
import { date } from 'quasar'
import type { client_client } from 'schema-types/client'
import type { clientStatuses } from 'schema-types/clientStatuses'
import type { deleteClient } from 'schema-types/deleteClient'
import type { siteUrls, siteUrls_sites } from 'schema-types/siteUrls'
import { defineComponent, h } from 'vue'
import { FacebookLoader } from 'vue-content-loader'

const { formatDate } = date

export default defineComponent({
    name: 'Client',

    data() {
        return {
            isUser: false,
            emailSites,
            transitionName: '',
            editDialog: false,
            markingClientAsScanned: false,
            movingClientToMonitoring: false,
            sites: [] as Array<{ id: string; name: string }>,
            clientQuery: gql`
        query client($clientId: String!) {
          client(id: $clientId) {
            ...AllClientData
            movedToMonitoringAt
            nextScan
            scannedAt
            assignedTo {
              id
              name
            }
            deepScanStatus
            stripeSubscriptionStatus
            spouseInfo {
              id
              email
            }
          }
        }
        ${AllClientData}
      `,
            appName: process.env.VUE_APP_NAME,
        }
    },

    apollo: {
        currentUser: currentUserQuery,

        sites: gql`
      query siteIds {
        sites {
          id
          name
        }
      }
    `,
    },

    mounted() {
        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })
    },

    components: {
        FacebookLoader,

        ClientEditor,

        InformationLabel: defineComponent({
            name: 'InformationLabel',

            render() {
                return h(
                    'span',
                    {
                        staticStyle: { 'font-size': '14px' },
                        class: 'text-grey-10 text-weight-bold',
                    },
                    (this as any).$slots.default(),
                )
            },
        }),
    },

    created() {
        this.$router.beforeEach((to, from, next) => {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length

            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

            next()
        })
    },

    methods: {
        openSpousePage(spouseId: string) {
            window.open(`/client/${spouseId}`, '_blank');
        },

        openStripeSearch(email: string) {
            const encodedEmail = encodeURIComponent(email);
            const stripeSearchUrl = `https://dashboard.stripe.com/search?query=is%3Acustomers%20${encodedEmail}`;
            window.open(stripeSearchUrl, '_blank');
        },

        getStripeStatusColor(status: string) {
            switch (status) {
                case 'ACTIVE':
                    return 'green'
                case 'TRIALING':
                    return 'blue'
                case 'PASTDUE':
                    return 'orange'
                case 'CANCELED':
                    return 'red'
                default:
                    return 'grey'
            }
        },

        openBrowser(url: string) {
            window.open(url, '_blank')
        },

        formatDate(dateString: string) {
            return formatDate(Date.parse(dateString), 'MMMM Do, YYYY')
        },

        getAge(dateOfBirth: number, age: string) {
            if (!dateOfBirth || (typeof dateOfBirth !== 'string' && typeof dateOfBirth !== 'number')) {
                return age
            }

            const birthDate = new Date(dateOfBirth)

            const ageDifMs = Date.now() - birthDate.getTime()
            const ageDate = new Date(ageDifMs)

            return `${Math.abs(ageDate.getUTCFullYear() - 1970).toString() !== 'NaN'
                ? Math.abs(ageDate.getUTCFullYear() - 1970)
                : 'Invalid date format'
                }`
        },

        showMonitoringDetails(client: client_client) {
            this.$q.dialog({
                title: 'Monitoring Details',
                message: `
        Moved to Monitoring: ${formatForDisplay(client.movedToMonitoringAt, 'movedToMonitoringAt')}
        Next Manual Scan: ${formatForDisplay(client.nextScan, 'nextScan')}
        Manually Scanned At: ${formatForDisplay(client.scannedAt, 'scannedAt')}
        `,
                ok: {
                    label: 'Close',
                    flat: true,
                },
                style: 'white-space: pre',
            })
        },

        markClientAsScanned(clientId: string) {
            this.$q
                .dialog({
                    title: 'Mark client as manually scanned?',
                    message: 'It will be recorded that the client has been checked on all sites.',
                    cancel: true,
                })
                .onOk(async () => {
                    try {
                        this.markingClientAsScanned = true
                        await this.$apollo.mutate({
                            mutation: gql`
                mutation markClientAsScanned($clientId: String!) {
                  markClientAsScanned(clientId: $clientId) {
                    id
                  }
                }
              `,
                            variables: {
                                clientId,
                            },
                            refetchQueries: ['clients'],
                        })

                        // handle if client is opened from queue or search
                        const urlParams = new URLSearchParams(window.location.search)
                        if (urlParams.get('fromqueue') === 'true') {
                            this.$q.notify('Client marked as manually scanned')
                            this.$router.push(`/queue?clientId=${clientId}`)
                            this.markingClientAsScanned = false
                        } else {
                            this.$q.notify('Client marked as manually scanned')
                            this.$router.push('/clients')
                            this.markingClientAsScanned = false
                        }
                    } catch (e: any) {
                        this.markingClientAsScanned = false
                        this.$q.dialog({
                            title: 'Error marking client as manually scanned',
                            message: e.message.replace('GraphQL error: ', ''),
                        })
                    }
                })
        },

        async moveClientToMonitoring(id: string) {
            this.$q
                .dialog({
                    title: 'Move client to monitoring?',
                    message:
                        'The client will be marked as removed from all sites and will move to the monitoring phase.',
                    cancel: true,
                })
                .onOk(async () => {
                    try {
                        this.movingClientToMonitoring = true
                        await this.$apollo.mutate({
                            mutation: gql`
                mutation moveToMonitoring($id: String!) {
                  updateClient(id: $id, status: MONITORING, isManuallyUpdated: true) {
                    id
                    status
                  }
                }
              `,
                            variables: {
                                id,
                            },
                            refetchQueries: ['clients'],
                        })
                        this.$q.notify('Client moved to monitoring')
                        this.$router.push('/clients')
                        this.movingClientToMonitoring = false
                    } catch (e: any) {
                        this.movingClientToMonitoring = false
                        this.$q.dialog({
                            title: 'Error moving client to monitoring',
                            message: e.message.replace('GraphQL error: ', ''),
                        })
                    }
                })
        },

        async quickSearchAllSites(client: client_client, addressIndex = 0) {
            try {
                const { sites } = (
                    await this.$apollo.query<siteUrls>({
                        query: gql`
              query siteUrls {
                sites(onlyQuickSearchEnabled: true) {
                  id
                  quickSearchAllParameter
                  url
                }
              }
            `,
                    })
                ).data

                const injectClientData = (client: client_client, input: string) => {
                    // addresses
                    let res = input
                        .replace('{zipCode}', client.addresses.map((a) => `"${a.zipCode}"`).join(' OR '))
                        .replace('{city}', client.addresses.map((a) => `"${a.city}"`).join(' OR '))
                        .replace(
                            '{streetAddress}',
                            client.addresses
                                .map(
                                    (a) =>
                                        `"${client.includeStreetSuffixInQuickSearch
                                            ? a.streetAddress
                                            : a.streetAddress?.replace(/ +[^ ]+$/, '')
                                        }"`,
                                )
                                .join(' OR '),
                        )
                        .replace('{state}', client.addresses.map((a) => `"${a.state}"`).join(' OR '))
                    // names
                    const middleNameVariants = client.middleName
                        ? [undefined, client.middleName.substring(0, 1), client.middleName]
                        : [undefined]
                    const nameReplace: string[] = []
                    middleNameVariants.forEach((middleName) => {
                        ;[client.firstName, ...(client.Client_alternateFirstNames || [])].forEach(
                            (firstName) => {
                                ;[client.lastName, ...(client.Client_alternateLastNames || [])].forEach(
                                    (lastName) => {
                                        nameReplace.push(
                                            `"${firstName} ${middleName || ''} ${lastName}"`.replace('  ', ' '),
                                        )
                                    },
                                )
                            },
                        )
                    })
                    res = res.replace('{name}', nameReplace.join(' OR '))
                    return res
                }

                // Group sites by their search parameter
                const searchParameterToQueryMap: { [index: string]: string } = {
                    ZIPCODE: '{zipCode}',
                    STREETADDRESS: '{streetAddress}',
                    CITYSTATE: '{city} {state}',
                }
                const sitesBySearchParameter = sites.reduce((acc, site) => {
                    if (acc[site.quickSearchAllParameter!] === undefined) {
                        acc[site.quickSearchAllParameter!] = []
                    }
                    acc[site.quickSearchAllParameter!].push(site)
                    return acc
                }, {} as { [key: string]: siteUrls_sites[] })

                // Launch Google search for each parameter group
                Object.entries(sitesBySearchParameter).forEach(([searchParameter, sites]) => {
                    const personInformation = injectClientData(
                        client,
                        `{name} ${searchParameterToQueryMap[searchParameter]}`,
                    )

                    const siteQueries = [
                        sites.map(({ url }: { url: string }) => 'site:' + url.replace(/^https?:\/\//, '')),
                    ]

                    // Google search queries are limited to 32 words, split into two if it's too long
                    if (
                        siteQueries[0].join(' OR ').split(' ').length + personInformation.split(' ').length >
                        32
                    ) {
                        siteQueries.push(siteQueries[0].splice(Math.round(siteQueries[0].length / 2)))
                    }

                    siteQueries.forEach((siteQuery) => {
                        openSiteUrl(
                            'https://www.google.com',
                            `/search?q=${personInformation} ${siteQuery.join(' OR ')}`,
                            client,
                            addressIndex,
                        )
                    })
                })
            } catch (e: any) {
                this.$q.dialog({
                    title: 'Error loading site urls',
                    message: e.message.replace('GraphQL error: ', ''),
                })
            }
        },

        async generateReport(client: client_client, reportType: ReportType) {
            const namePrefixes: { [key in ReportType]: string } = {
                Initial: 'INI',
                Intermediate: 'INT',
                Final: 'FIN',
                Monitored: 'MONT',
            }
            const { data } = await this.$apollo.query<clientStatuses>({
                query: gql`
          query clientStatuses($clientId: String!, $manualChecksOnly: Boolean) {
            siteStatuses(clientId: $clientId, manualChecksOnly: $manualChecksOnly) {
              id
              site
              status
            }
            sites {
              id
              automationName
            }
          }
        `,
                variables: {
                    clientId: this.$route.params.clientId,
                },
                // Changing status does not update cache, so have to fetch from network
                fetchPolicy: 'no-cache',
            })
            if (data.siteStatuses && data.sites) {
                const siteStatuses: { [index: string]: string } = {}
                data.sites.forEach(({ id, automationName }) => {
                    if (automationName) {
                        const status = (
                            data.siteStatuses.find(({ site }) => site === id) || {
                                status: 'UNKNOWN',
                            }
                        ).status
                        const customDisplayStatuses = {
                            ...displayStatuses,
                            NOEXIST: 'Not Listed and Actively Monitoring',
                            REMOVED: 'Removed And Actively Monitoring',
                            ADDEDANDREMOVED: 'Re-Removed and Actively Monitoring',
                        }
                        siteStatuses[automationName] = customDisplayStatuses[status]
                    }
                })
                const saveFileName = `${namePrefixes[reportType]} Report for ${client.firstName} ${client.lastName
                    } on ${this.formatDate(new Date().toISOString())}.pdf`
                await generateReport(saveFileName, {
                    date: this.formatDate(new Date().toISOString()),
                    firstName: client.firstName,
                    message: reportMessages[reportType],
                    ...siteStatuses,
                })
            }
        },

        deleteClient() {
            this.$q
                .dialog({
                    title: 'Delete Client?',
                    message:
                        'Are you sure you want to delete this client? This action is permanent and cannot be undone.',
                    ok: {
                        label: 'Delete',
                        color: 'negative',
                        flat: true,
                    },
                    cancel: {
                        color: 'grey-10',
                        flat: true,
                    },
                })
                .onOk(() => {
                    this.$router.back()
                    this.$apollo
                        .mutate<deleteClient>({
                            mutation: gql`
                mutation deleteClient($id: String!) {
                  deleteClient(id: $id) {
                    id
                  }
                }
              `,
                            variables: {
                                id: this.$route.params.clientId,
                            },
                            update: (store, { data }) => {
                                const clientsQuery = gql`
                  query clientIds {
                    clients(
                      page: 1
                      perPage: 10000000
                      manualChecksOnly: false
                      reAddedOnly: false
                    ) {
                      data {
                        id
                      }
                    }
                  }
                `
                                const { clients } = store.readQuery<any>({
                                    query: clientsQuery,
                                }) || { clients: [] }

                                if (clients && data) {
                                    const clientToRemove = clients.data?.find(
                                        ({ id }: { id: string }) => id === data.deleteClient.id,
                                    )

                                    if (clientToRemove) {
                                        const index = clients?.data?.indexOf(clientToRemove)
                                        clients.data?.splice(index, 1)
                                    }

                                    store.writeQuery({
                                        query: clientsQuery,
                                        data: { clients },
                                    })
                                }
                            },
                        })
                        .then(() => {
                            this.$q.notify('Client has been deleted.')
                        })
                        .catch((error) => {
                            this.$q.dialog({
                                title: 'Error Deleting Client',
                                message: error.message.replace('GraphQL error: ', '').trim(),
                            })
                        })
                })
        },

        goToNextSite() {
            const siteIds = [...this.sites]
                .sort(({ name: a }, { name: b }) => (a > b ? 1 : -1))
                .map(({ id }) => id)
            const currentSiteIndex = siteIds.indexOf(this.$route.params.siteId as string)
            if (currentSiteIndex + 1 >= siteIds.length) {
                this.$router.replace(`/client/${this.$route.params.clientId}`)
            } else {
                this.$router.replace(
                    `/client/${this.$route.params.clientId}/site/${siteIds[currentSiteIndex + 1]}`,
                )
            }
        },

        openMoreInfo() {
            const clientId = this.$route.params.clientId
            const url = `${window.location.origin}/client-more-info/${clientId}`
            window.open(url, '_blank', 'width=800,height=600')
        }
    },
})
