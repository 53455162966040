import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import type { createUser } from 'schema-types/createUser'
import type { updateUser } from 'schema-types/updateUser'
import { AllUserData } from '@/fragments'
import TwoFactorEnable from '@/components/TwoFactorEnable/TwoFactorEnable.vue'
import { passwordRules } from '@/utils'
import { currentUserQuery } from '@/queries'
import { TypeSelections } from '@/components/TypeSelector/TypeSelector'

export default defineComponent({
  name: 'UserEditor',
  data() {
    return {
      required: (val?: string) => (val && val.length > 0) || 'This field is required',
      passwordRules,
      loading: false,
      twoFactorDialog: false,
      userData: {
        id: '',
        name: '',
        email: '',
        password: '',
        authLevel: 'USER',
        twoFactorEnabled: false,
        twoFactorRequired: false,
        accountLocked: false,
        deepScanPermissions: [] as string[],
      },
      clientStatuses: TypeSelections.map((el) => el.value),
      showStatuses: [] as string[],
      currentUserQuery,
    }
  },

  props: ['user'],
  mounted() {
    const { user } = this.$props

    if (user) {
      const temp = { ...user }
      delete temp.__typename
      this.userData = temp
    }
  },

  methods: {
    saveUser() {
      this.loading = true

      if (this.userData.id) {
        this.$apollo
          .mutate<updateUser>({
            mutation: gql`
              mutation updateUser(
                $id: String!
                $name: String
                $password: String
                $email: String
                $authLevel: User_authLevel
                $twoFactorEnabled: Boolean
                $twoFactorRequired: Boolean
                $accountLocked: Boolean
                $deepScanPermissions: [ClientType!]
              ) {
                updateUser(
                  id: $id
                  name: $name
                  password: $password
                  email: $email
                  authLevel: $authLevel
                  twoFactorEnabled: $twoFactorEnabled
                  twoFactorRequired: $twoFactorRequired
                  accountLocked: $accountLocked
                  deepScanPermissions: $deepScanPermissions
                ) {
                  ...AllUserData
                }
              }
              ${AllUserData}
            `,
            variables: this.userData,
          })
          .then(() => {
            this.loading = false
            this.$q.dialog({
              title: 'Success',
              message: 'User information was updated.',
            })
          })
          .catch((err) => {
            this.loading = false

            this.$q.dialog({
              title: 'Error',
              message: err.message.replace('GraphQL error:', '').trim(),
            })
          })
      } else {
        this.$apollo
          .mutate<createUser>({
            mutation: gql`
              mutation createUser(
                $name: String!
                $email: String!
                $authLevel: User_authLevel!
                $password: String!
              ) {
                createUser(name: $name, email: $email, authLevel: $authLevel, password: $password) {
                  ...AllUserData
                }
              }
              ${AllUserData}
            `,
            variables: this.userData,
            refetchQueries: ['users'],
          })
          .then(() => {
            this.loading = false

            this.$emit('created')

            this.userData = {
              id: '',
              name: '',
              email: '',
              password: '',
              authLevel: 'USER',
              twoFactorEnabled: false,
              twoFactorRequired: false,
              accountLocked: false,
              deepScanPermissions: [],
            }
          })
          .catch((err) => {
            this.loading = false

            this.$q.dialog({
              title: 'Error',
              message: err.message.replace('GraphQL error:', '').trim(),
            })
          })
      }
    },

    showTwoFactorDialog() {
      if (!this.userData.twoFactorEnabled && !this.$props.user.twoFactorEnabled) {
        this.twoFactorDialog = true
      }
    },
  },

  components: {
    TwoFactorEnable,
  },
})
