/**
 * Used to track conversions for Google ads via gtag events
 * Only sends event in production
 */
export const trackConversion = (transactionId?: string): void =>
  process.env.NODE_ENV === 'production'
    ? gtag('event', 'conversion', {
        send_to: 'AW-546078047/rQAhCLLZpuIBEN_6sYQC',
        transaction_id: transactionId,
      })
    : undefined
