import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createApolloProvider } from '@vue/apollo-option'
import { currentUserQuery } from '@/queries'
import { ApolloLink } from '@apollo/client/link/core'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  const user = localStorage.getItem('user') || null

  return {
    headers: {
      ...headers,
      Authorization: user ? `${JSON.parse(user)?.token}` : '',
      refreshToken: user ? `${JSON.parse(user)?.refreshToken}` : '',
    },
  }
})

const responseHeadersMiddleware = new ApolloLink((operation, forward) => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null

  return forward(operation).map((response) => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers && user) {
      const refreshToken = headers.get('refreshToken')
      const appToken = headers.get('appToken')

      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          refreshToken: refreshToken || user.refreshToken,
          token: appToken || user.token,
        }),
      )
    }

    return response
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
          null,
          2,
        )}, Path: ${path}`,
      )

      if (message?.toLowerCase().includes('authenticated')) {
        localStorage.removeItem('user')
        window.location.reload()
      }
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

// Cache implementation
const clientCache = new InMemoryCache()
const handleClientCache = () => {
  const user = localStorage.getItem('user') || null

  return clientCache.writeQuery({
    query: currentUserQuery,
    data: {
      currentUser: user ? JSON.parse(user) : null,
    },
  })
}
handleClientCache()

export const apolloClient = new ApolloClient({
  link: from([errorLink, responseHeadersMiddleware, authLink, httpLink]),
  cache: clientCache,
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

export default apolloProvider
