<template>
  <div class="flex flex-col">
    <q-select
      filled
      use-input
      :modelValue="modelValue"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
      input-debounce="250"
      label="User Type"
      :options="userTypes"
    >
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey">No results</q-item-section>
        </q-item>
      </template>
      <template v-slot:after>
        <q-btn
          @click="$emit('update:modelValue', { value: '', label: '' })"
          icon="clear"
          flat
          round
        >
          <q-tooltip>Clear Assignments</q-tooltip>
        </q-btn>
      </template>
    </q-select>
  </div>
</template>

<script src="./TypeSelector.ts"></script>
