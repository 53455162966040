import Rollbar from 'rollbar'
import type { App } from 'vue'

const addRollbarToVue = (app: App): void => {
  // Set the Rollbar instance in the Vue prototype
  // before creating the first Vue instance.
  // This ensures it is available in the same way for every
  // instance in your app.
  app.config.globalProperties.$rollbar = new Rollbar({
    accessToken: 'f00e116e77954174b6c7e6dfbb4750c5',
    captureUncaught: true,
    captureUnhandledRejections: true,
  })

  // If you have already set up a global error handler,
  // just add `vm.$rollbar.error(err)` to the top of it.
  // If not, this simple example will preserve the app’s existing
  // behavior while also reporting uncaught errors to Rollbar.
  app.config.errorHandler = (err, vm) => {
    ;(vm as any).$rollbar.error(err)
    throw err // rethrow
  }

  // Create the Vue app instance after adding the Rollbar
  // instance to the Vue prototype above.
  // Your app may pass different options here.
}

export { addRollbarToVue }
