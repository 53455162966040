import UserEditor from '@/components/UserEditor/UserEditor.vue'
import { AllUserData } from '@/fragments'
import { currentUserQuery, usersQuery } from '@/queries'
import { hasAuthLevel, User_authLevel } from '@/utils'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'
import { FacebookLoader } from 'vue-content-loader'

import UserClientList from '@/components/UserClientList/UserClientList.vue'
import ClientList from '@/views/ClientList/ClientList.vue'
import type { deleteUser } from 'schema-types/deleteUser'
import type { user_user } from 'schema-types/user'


export default defineComponent({
    name: 'User',

    data() {
        return {
            isUser: false,
            isAdmin: false,
            isOwner: false,
            twoFactorRecoveryDialog: false,
            editDialog: false,
            userQuery: gql`
        query user($userId: String!) {
          user(id: $userId) {
            ...AllUserData
          }
        }
        ${AllUserData}
      `,
            twoFactorDisableMutation: gql`
        mutation twoFactorDisable($userId: String!) {
          updateUser(id: $userId, twoFactorEnabled: false) {
            id
            twoFactorEnabled
          }
        }
      `,
        }
    },

    apollo: {
        currentUser: {
            query: currentUserQuery,
        },
    },

    mounted() {
        hasAuthLevel(this.$apollo, User_authLevel.ADMIN).then((isAdmin) => {
            this.isAdmin = isAdmin
        })

        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })

        hasAuthLevel(this.$apollo, User_authLevel.OWNER).then((isOwner) => {
            this.isOwner = isOwner
        })
    },

    methods: {
        openStripeCustomerPortal() {
            this.$apollo
                .mutate({
                    mutation: gql`
            mutation getStripeCustomerPortalUrl {
              getStripeCustomerPortalUrl
            }
          `,
                })
                .then(({ data }) => {
                    window.open(data.getStripeCustomerPortalUrl, '_blank')
                })
                .catch((err) => {
                    this.$q.dialog({
                        title: err.graphQLErrors.some(
                            (e: any) => e?.extensions?.code === 'NO_STRIPE_CUSTOMER_ID',
                        )
                            ? 'Manage Account'
                            : 'Error',
                        message: err.message.replace('GraphQL error: ', ''),
                    })
                })
        },

        deleteUser(user: user_user) {
            this.$q
                .dialog({
                    title: 'Delete User',
                    message: `Are you sure you want to delete ${user.name}? This cannot be undone.`,
                    ok: {
                        label: 'Delete',
                        color: 'negative',
                        outline: true,
                    },
                    cancel: {
                        flat: true,
                        color: 'gray-10',
                    },
                })
                .onOk(() => {
                    this.$apollo
                        .mutate<deleteUser>({
                            mutation: gql`
                mutation deleteUser($id: String!) {
                  deleteUser(id: $id) {
                    id
                  }
                }
              `,
                            variables: {
                                id: user.id,
                            },
                            update: (store, { data }) => {
                                if (data && data.deleteUser) {
                                    const { users: userList } = store.readQuery<any>({
                                        query: usersQuery,
                                    }) || { users: [] }
                                    if (userList) {
                                        const userToRemove = userList.data?.find(
                                            ({ id }: { id: string }) => id === data.deleteUser.id,
                                        )
                                        if (userToRemove) {
                                            userList.data.splice(userList.data.indexOf(userToRemove), 1)
                                            store.writeQuery({
                                                query: usersQuery,
                                                data: { users: userList },
                                            })
                                        }
                                    }
                                }
                            },
                            refetchQueries: [{ query: usersQuery }],
                        })
                        .then(() => {
                            this.$q.notify('User deleted.')
                            this.$router.push('/users')
                        })
                        .catch((error) => {
                            this.$q.dialog({
                                title: 'Error Deleting User',
                                message: error.message.replace('GraphQL error: ', '').trim(),
                            })
                        })
                })
        },
    },

    components: {
        FacebookLoader,
        UserEditor,
        UserClientList,
        ClientList
    },
})
