<template>
  <div>
    <q-btn color="primary" outline @click="showDialog = true">Edit Site Categories</q-btn>
    <q-dialog v-model="showDialog">
      <q-card class="q-pa-md text-center">
        <div class="col q-gutter-sm">
          <div class="row justify-center" v-for="category in siteCategories" :key="category.id">
            <q-input v-model="values[category.id]"></q-input>
          </div>
        </div>
        <div class="row q-gutter-sm">
          <q-btn class="q-mt-md" @click="createNew">Create New</q-btn>
          <q-btn class="q-mt-md" @click="saveChanges">Save Changes</q-btn>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script src="./SiteCategoriesEditor.ts"></script>
