import { formatForDisplay, localStoreFilters } from '@/utils'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'
import type { ApolloError } from '@apollo/client'
import { sitesForPositiveHits_sites } from 'schema-types/sitesForPositiveHits'
import { reports as reportsType } from 'schema-types/reports'
import { clientsForReports_clients_data } from 'schema-types/clientsForReports'
import date from 'quasar/src/utils/date/date.js';

const sites = gql`
  query sitesForReports($onlyDeepScanEnabled: Boolean) {
    sites(onlyDeepScanEnabled: $onlyDeepScanEnabled) {
      name
      id
    }
  }
`
const clients = gql`
  query clientsForReports(
    $page: Int!
    $perPage: Int!
    $manualChecksOnly: Boolean
    $hideStatuses: [Client_status!]
  ) {
    clients(
      page: $page
      perPage: $perPage
      manualChecksOnly: $manualChecksOnly
      hideStatuses: $hideStatuses
    ) {
      data {
        id
        firstName
        lastName
      }
    }
  }
`
const reports = gql`
  query reports($page: Int!, $perPage: Int!, $orderBy: [OrderBy!]) {
    reports(page: $page, perPage: $perPage, orderBy: $orderBy) {
      data {
        name
        createdAt
        report
        status
      }
      totalPages
      totalCount
    }
  }
`
const generateDeepScanReport = gql`
  mutation generateDeepScanReport(
    $siteIds: [String!]
    $clientIds: [String!]
    $siteStatuses: [RemovalStatus!]
    $dateRange: DateRangeInput!
  ) {
    generateDeepScanReport(
      siteIds: $siteIds
      clientIds: $clientIds
      siteStatuses: $siteStatuses
      dateRange: $dateRange
    ) {
      name
    }
  }
`

export default defineComponent({
  name: 'Reports',

  data() {
    const filters = localStoreFilters.getItem('reports-filter') as
      | {
          sortBy: string
          descending: boolean
          rowsPerPage: number
        }
      | undefined

    return {
      selectReport: { id: 1, name: 'Deep Scan Tracker' },
      selectStatuses: undefined as { id: string; name: string }[] | undefined,
      reports: [] as Omit<reportsType['reports']['data'], '__typename'>,
      reportsError: false as boolean | ApolloError,
      sites: [] as sitesForPositiveHits_sites[],
      clients: [] as clientsForReports_clients_data[],
      table: {
        columns: [
          {
            name: 'name',
            label: 'Report name',
            align: 'left',
            field: 'name',
            sortable: true,
          },
          {
            name: 'createdAt',
            align: 'left',
            label: 'Date',
            field: 'createdAt',
            sortable: true,
          },
          {
            name: 'status',
            align: 'left',
            label: 'Status',
            field: 'status',
            sortable: true,
          },
          {
            name: 'report',
            align: 'left',
            label: 'Export',
            field: 'report',
            headerStyle: 'width: 174px; text-align: center;',
            sortable: false,
          },
        ],
        siteNameFilter: undefined as { id: string; name: string }[] | undefined,
        clientNameFilter: undefined as { id: string; name: string }[] | undefined,
        orderBy: [{ field: 'createdAt', direction: 'desc' }],
        page: 1,
        totalPages: 1,
        pagination: {
          sortBy: filters?.sortBy || 'createdAt',
          descending: filters?.descending || false,
          rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
        },
      },
      showFiltering: false,
      dateType: 'TODAY' as
        | 'TODAY'
        | 'YESTERDAY'
        | 'THIS_WEEK'
        | 'LAST_WEEK'
        | 'THIS_MONTH'
        | 'LAST_MONTH'
        | 'CUSTOM_RANGE',
      dateOptions: { from: undefined, to: undefined } as {
        from: Date | undefined
        to: Date | undefined
      },
      sitesOptions: [] as { id: string; name: string }[],
      clientsOptions: [] as { id: string; name: string }[],
      statusesOptions: [
        { id: 'ADDEDANDREMOVED', name: 'Added and Removed' },
        { id: 'REMOVALREQUESTED', name: 'Removal Requested' },
        { id: 'REMOVED', name: 'Removed' },
      ],
      appDisplayName: process.env.VUE_APP_DISPLAYNAME,
      generateReportLoading: false,
    }
  },

  apollo: {
    reports: {
      fetchPolicy: 'no-cache',
      query: reports,
      variables() {
        return {
          orderBy: this.table.orderBy,
          page: this.table.page,
          perPage: this.table.pagination.rowsPerPage || 1000 * 1000,
        }
      },
      result({ data }: { data: reportsType }) {
        this.reportsError = this.reportsError || false

        if (data?.reports?.data?.length) {
          this.table.totalPages = data.reports.totalPages
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.reports =
            data.reports.data?.map((el) => ({
              name: el.name,
              createdAt: el.createdAt,
              status: el.status,
              report: el.report,
            })) || []
        } else {
          this.reports = []
        }
      },
      error(error) {
        this.reportsError = this.reportsError || error
      },
    },

    sites: {
      query: sites,
      variables() {
        return {
          onlyDeepScanEnabled: true,
        }
      },
      result({ data }) {
        if (data) {
          this.reportsError = this.reportsError || false
          this.sites = data.sites
          this.sitesOptions = data.sites?.map((el: any) => ({ id: el.id, name: el.name }))
        }
      },
      error(err) {
        this.reportsError = this.reportsError || err
      },
    },

    clients: {
      query: clients,
      variables() {
        return {
          page: 1,
          perPage: 1000000,
          manualChecksOnly: false,
          hideStatuses: ['SELFREMOVAL', 'DRAFT'],
        }
      },
      result({ data }) {
        if (data) {
          this.reportsError = this.reportsError || false

          this.clients = data.clients?.data

          this.clientsOptions = this.clients?.map((el: any) => ({
            id: el.id,
            name: `${el.firstName} ${el.lastName}`,
          }))
        }
      },
      error(err) {
        this.reportsError = this.reportsError || err
      },
    },
  },

  computed: {
    formattedDateRange() {
      return `${this.dateOptions?.from || ''} - ${this.dateOptions?.to || ''}`
    },
  },

  methods: {
    date() {
      return date
    },

    saveFilters() {
      localStoreFilters.setItem('reports-filter', {
        sortBy: this.table.pagination.sortBy,
        descending: this.table.pagination.descending,
        rowsPerPage: this.table.pagination.rowsPerPage,
      })
    },

    formatForDisplay(data: any, name: string) {
      if (name === 'status' && typeof data === 'string') {
        return data.toLowerCase().replace(/^./, (t) => t.toUpperCase())
      }
      return formatForDisplay(data, name)
    },

    filterFnSites(val: string, update: any) {
      if (val === '') {
        update(() => {
          this.sitesOptions = this.sites?.map((el) => ({ id: el.id, name: el.name }))
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.sitesOptions = this.sites
          ?.map((el) => ({ id: el.id, name: el.name }))
          ?.filter((e) => e.name.toLowerCase().includes(needle.trimStart()))
      })
    },

    filterFnClients(val: string, update: any) {
      if (val === '') {
        update(() => {
          this.clientsOptions = this.clients?.map((el) => ({
            id: el.id,
            name: `${el.firstName} ${el.lastName}`,
          }))
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.clientsOptions = this.clients
          ?.map((el) => ({ id: el.id, name: `${el.firstName} ${el.lastName}` }))
          ?.filter((e) => e.name.toLowerCase().includes(needle.trimStart()))
      })
    },

    async generateReport() {
      this.generateReportLoading = true

      await this.$apollo
        .mutate({
          mutation: generateDeepScanReport,
          variables: {
            siteIds: this.table.siteNameFilter?.map((el) => el.id),
            clientIds: this.table.clientNameFilter?.map((el) => el.id),
            siteStatuses: this.selectStatuses?.map((el) => el.id),
            dateRange: {
              type: this.dateType,
              startDate: date.formatDate(this.dateOptions.from, 'YYYY-MM-DD'),
              endDate: date.formatDate(this.dateOptions.to, 'YYYY-MM-DD'),
            },
          },
        })
        .then(() => {
          this.table.siteNameFilter = undefined
          this.table.clientNameFilter = undefined
          this.selectStatuses = undefined
          this.dateType = 'TODAY'
          this.dateOptions = { from: undefined, to: undefined }

          this.$apollo.queries.reports.refetch().finally()

          this.showFiltering = false

          this.$q.dialog({
            title: 'Report',
            message: 'Report generated',
          })
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error create report',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })
        .finally(() => (this.generateReportLoading = false))
    },

    generateToCSV(value: string) {
      const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', 'Report Deep Scan')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    customRage() {
      this.dateType = 'CUSTOM_RANGE'
      this.dateOptions = {
        from: date.formatDate(new Date(Date.now()), 'MM/DD/YYYY').toString(),
        to: date.formatDate(new Date(new Date(Date.now()).setHours(24)), 'MM/DD/YYYY'),
      }
    },
  },

  mounted() {
    this.$apollo.queries.reports.refetch().finally()
    this.$apollo.queries.sites.refetch()
  },

  watch: {
    'table.pagination': {
      handler(prev, next) {
        this.saveFilters()

        if (next.sortBy || prev.sortBy) {
          this.table.orderBy = [
            {
              field: next.sortBy || prev.sortBy,
              direction: (next.sortBy ? !next.descending : !prev.descending) ? 'asc' : 'desc',
            },
          ]
        }
      },
    },

    dateType: {
      handler(to) {
        if (to !== 'CUSTOM_RANGE') {
          this.dateOptions = { from: undefined, to: undefined }
        }
      },
    },
  },
})
