import { RemovalStatus } from './../../../schema-types/globalTypes'

import { defineComponent } from 'vue'

export const cleanedStatus = {
    [RemovalStatus.UNKNOWN]: 'Pending',
    [RemovalStatus.NOEXIST]: 'Not Listed',
    [RemovalStatus.EXIST]: 'Listed',
    [RemovalStatus.REMOVALREQUESTED]: 'Removal Requested',
    [RemovalStatus.REMOVED]: 'Removed',
    [RemovalStatus.ADDEDANDREMOVED]: 'Added And Removed',
}

export default defineComponent({
    name: 'SiteStatusSelector',
    props: ['modelValue'],
    data() {
        return {
            // siteStatuses: [] as Array<{ label: string; value: string }>,
            siteStatuses: Object.values(RemovalStatus).map((status) => ({
                label: cleanedStatus[status],
                value: status,
            })),
        }
    },
    emits: ['update:modelValue'],
    // methods: {
    //   searchUsers(val: string, update: (updateFn: () => void) => void) {
    //     this.$apollo
    //       .query<usersSearch>({
    //         query: gql`
    //           query usersSearch($search: String) {
    //             users(search: $search) {
    //               id
    //               name
    //               email
    //             }
    //           }
    //         `,
    //         variables: {
    //           search: val,
    //         },
    //       })
    //       .then(({ data }) => {
    //         update(() => {
    //           this.assignmentUsers = data.users.map((user) => ({
    //             label: user.name,
    //             value: user.id,
    //           }))
    //         })
    //       })
    //   },
    // },
})
