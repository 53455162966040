import gql from 'graphql-tag'

export const instructionDocumentsQuery = gql`
  query instructionDocuments {
    instructionDocuments {
      id
      name
      url
    }
  }
`
