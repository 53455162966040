import { formatForDisplay, sortWithDate } from '@/utils'
import gql from 'graphql-tag'
import type { securityEvents_securityEvents } from 'schema-types/securityEvents'
import { defineComponent } from 'vue'

const securityEventTypeDisplay: { [index: string]: string } = {
    INVALIDPASSWORDRESETEMAIL: 'Invalid Password Reset Email',
    INCORRECTPASSWORD: 'Incorrect Password',
    RESTRICEDQUERY: 'Restricted Query',
}

export default defineComponent({
    name: 'SecurityEvents',
    data() {
        return {
            queryError: false,
            onlyDeleted: false,
            securityEvents: [] as securityEvents_securityEvents[],
            table: {
                columns: [
                    {
                        name: 'createdAt',
                        required: true,
                        label: 'Created At',
                        align: 'left',
                        field: 'createdAt',
                        sortable: true,
                    },
                    {
                        name: 'updatedAt',
                        required: true,
                        label: 'Updated At',
                        align: 'left',
                        field: 'updatedAt',
                        sortable: true,
                    },
                    {
                        name: 'ip',
                        required: true,
                        label: 'IP',
                        align: 'left',
                        field: 'ip',
                        sortable: true,
                    },
                    {
                        name: 'type',
                        align: 'left',
                        label: 'Event Type',
                        field: 'type',
                        sortable: true,
                    },
                    {
                        name: 'attempts',
                        align: 'left',
                        label: 'Attempts',
                        field: 'attempts',
                        sortable: true,
                    },
                    {
                        name: 'successful',
                        align: 'left',
                        label: 'Successful',
                        field: 'successful',
                        sortable: true,
                    },
                ],
                pagination: {
                    sortBy: 'createdAt',
                    page: 1,
                    rowsPerPage: 50,
                },
            },
        }
    },
    apollo: {
        securityEvents: {
            query: gql`
        query securityEvents($onlyDeleted: Boolean) {
          securityEvents(onlyDeleted: $onlyDeleted) {
            id
            createdAt
            updatedAt
            ip
            type
            emails {
              value
            }
            attempts
            successful
            deleted
            user {
              id
              name
              email
            }
            emailUsed
          }
        }
      `,
            variables() {
                return {
                    onlyDeleted: (this as any).onlyDeleted,
                }
            },
            error(err) {
                ; (this as any).queryError = err
            },
            result({ data }) {
                if (data) {
                    ; (this as any).queryError = false
                }
            },
        },
    },
    methods: {
        formatForDisplay(data: any, name: string) {
            if (name === 'type') {
                return securityEventTypeDisplay[data]
            }
            return formatForDisplay(data, name)
        },
        sortWithDate,
        filterColumnData(columnData: { [index: string]: string }) {
            const columnOrder = this.table.columns.map(({ field }) => field)
            const exclude = ['__index', 'id', '__typename', 'emails', 'user', 'deleted', 'emailUsed']
            return Object.keys(columnData)
                .filter((key) => !exclude.includes(key))
                .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
                .reduce((obj: { [index: string]: string }, key) => {
                    obj[key] = columnData[key]
                    return obj
                }, {})
        },
        showDetails(eventId: string) {
            const event = (this as any).securityEvents.find(({ id }: { id: string }) => id === eventId)
            console.log('Eventid', { eventId, event })
            if (event) {
                this.$q
                    .dialog({
                        title: 'Event Details',
                        message: `    Event type: ${(this as any).formatForDisplay(event.type, 'type')}
    Created at: ${(this as any).formatForDisplay(event.createdAt, 'createdAt')}
    Updated at: ${(this as any).formatForDisplay(event.updatedAt, 'updatedAt')}
    IP address: ${event.ip}
    Attempts: ${event.attempts}
    Emails: ${event.emails?.map((email: string) => `\n        ${email}`) ?? ''}
    Email Attempted: ${event.emailUsed}
    Successful: ${(this as any).formatForDisplay(event.successful, 'successful')}
    ${event.user
                                ? `User:
        Name: ${event.user.name}
        Email: ${event.user.email}`
                                : ''
                            }`,
                        style: 'white-space: pre',
                        ok: event.deleted
                            ? {
                                flat: true,
                                label: 'Close',
                            }
                            : {
                                color: 'negative',
                                label: 'Delete',
                                flat: true,
                            },
                        cancel: event.deleted
                            ? undefined
                            : {
                                flat: true,
                                label: 'Close',
                            },
                    })
                    .onOk(() => {
                        if (!event.deleted) {
                            this.$apollo.mutate({
                                mutation: gql`
                  mutation deleteSecurityEvent($id: String!) {
                    deleteSecurityEvent(id: $id) {
                      id
                    }
                  }
                `,
                                refetchQueries: ['securityEvents'],
                                variables: {
                                    id: event.id,
                                },
                            })
                        }
                    })
            }
        },
    },
})
