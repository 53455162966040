import AssignmentSelector from '@/components/AssignmentSelector/AssignmentSelector.vue'
import { AllClientData, StatusCounts } from '@/fragments'
import { User_authLevel, hasAuthLevel } from '@/utils'
import { cloneDeep } from '@apollo/client/utilities'
import gql from 'graphql-tag'
import date from 'quasar/src/utils/date/date.js';
import type { client_client, client_client_addresses } from 'schema-types/client'
import type { createClient } from 'schema-types/createClient'
import type { updateClient } from 'schema-types/updateClient'
import { defineComponent } from 'vue'
import { TypeSelections } from '../TypeSelector/TypeSelector'
const { formatDate } = date

export default defineComponent({
    name: 'ClientEditor',

    emits: ['mutationCompleted'],

    data() {
        return {
            required: (val?: string) => (val && val.length > 0) || 'This field is required',
            isAdmin: false,
            isUser: false,
            clientStatusOptions: [
                { label: 'Draft', value: 'DRAFT' },
                { label: 'Removal', value: 'REMOVAL' },
                { label: 'Monitoring', value: 'MONITORING' },
                { label: 'Archived', value: 'ARCHIVED' },
                { label: 'Self Removal', value: 'SELFREMOVAL' },
            ],
            clientStatusOptionsUser: [
                { label: 'Removal', value: 'REMOVAL' },
                { label: 'Monitoring', value: 'MONITORING' },
            ],
            userTypes: TypeSelections,
            loading: false,
            clientData: {
                id: '',
                firstName: '',
                lastName: '',
                middleName: '',
                nickname: '',
                maidenName: '',
                alternateFirstNames: [] as { value: string }[],
                alternateLastNames: [] as { value: string }[],
                status: 'REMOVAL',
                assignTo: {
                    label: '',
                    value: '',
                },
                emails: [''],
                phoneNumbers: [''],
                addresses: [
                    {
                        streetAddress: '',
                        city: '',
                        state: '',
                        zipCode: '',
                    },
                ],
                tempEmail: '' as string | null,
                tempEmailPassword: '' as string | null,
                department: '' as string | null,
                dateOfBirth: '' as string | null,
                age: '' as string | null,
                otherInfo: '' as string | null,
                movedToMonitoringAt: '',
                scannedAt: '',
                includeStreetSuffixInQuickSearch: false as boolean | null,
                ClientType: '' as string | null,
                spouses: [''],
                sendReportsTo: '' as string | null,
                stripeAccount: '' as string | null,
            },
            dates: '',
        }
    },

    watch: {
        dates() {
            this.dates = formatDate(this.dates, 'MM-DD-YYYY')
        },

        'clientData.status'(status) {
            if (status === 'MONITORING' && !this.clientData.movedToMonitoringAt) {
                this.clientData.movedToMonitoringAt = formatDate(Date.now(), 'MM-DD-YYYY')
            }

            this.clientData.dateOfBirth = formatDate(this.clientData.dateOfBirth, 'MM-DD-YYYY')
        },

        'clientData.dateOfBirth'(dateOfBirth) {
            if ((typeof dateOfBirth === 'string' || typeof dateOfBirth === 'number') && dateOfBirth) {
                const birthDate = new Date(dateOfBirth)

                const ageDifMs: any = Date.now() - birthDate.getTime()
                const ageDate: any = new Date(ageDifMs)

                this.clientData.age = `${Math.abs(ageDate.getUTCFullYear() - 1970).toString() !== 'NaN'
                    ? Math.abs(ageDate.getUTCFullYear() - 1970)
                    : 'Invalid date format'
                    }`
            }
        },
    },

    mounted() {
        hasAuthLevel(this.$apollo, User_authLevel.ADMIN).then((isAdmin) => {
            console.log("isAdmin", isAdmin)
            this.isAdmin = isAdmin
        })

        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })

        const client = this.$props.client as client_client

        if (client) {
            const temp = {
                ...cloneDeep(client),
                dateOfBirth: client.dateOfBirth ? formatDate(client.dateOfBirth, 'MM-DD-YYYY') : null,
                movedToMonitoringAt: client.movedToMonitoringAt
                    ? formatDate(client.movedToMonitoringAt, 'MM-DD-YYYY')
                    : undefined,
                scannedAt: client.scannedAt ? formatDate(client.scannedAt, 'MM-DD-YYYY') : undefined,
                addresses: client.addresses.map((address: client_client_addresses) => {
                    const temp = { ...address }
                    // @ts-expect-error __typename is required on type def
                    delete temp.__typename
                    return temp
                }),
                assignTo: client.assignedTo
                    ? {
                        value: client.assignedTo.id,
                        label: client.assignedTo.name,
                    }
                    : {
                        label: '',
                        value: '',
                    },
            }
            // @ts-expect-error __typename is required on type def
            delete temp.__typename
            const spouses = temp.Client_spouses?.map((item) => item.value)
            this.clientData = {
                ...temp,
                middleName: temp.middleName || '',
                nickname: temp.nickname || '',
                maidenName: temp.maidenName || '',
                alternateFirstNames: temp.Client_alternateFirstNames?.map((obj) => obj.value) || [],
                alternateLastNames: temp.Client_alternateLastNames?.map((obj) => obj.value) || [],
                emails: temp.Client_emails.map((mail) => mail.value) || [],
                phoneNumbers: temp.Client_phoneNumbers.map((phone) => phone.value) || [],
                spouses: spouses?.length ? spouses : [''],
            } as any
        }
    },
    methods: {
        saveClient() {
            this.loading = true

            if (this.clientData.id) {
                if (!this.clientData.dateOfBirth) {
                    this.clientData.dateOfBirth = null
                }

                this.$apollo
                    .mutate<updateClient>({
                        mutation: gql`
              mutation updateClient(
                $id: String!
                $firstName: String!
                $lastName: String!
                $middleName: String
                $nickname: String
                $maidenName: String
                $alternateFirstNames: [String!]
                $alternateLastNames: [String!]
                $email: String!
                $status: Client_status!
                $assignTo: String!
                $emails: [String!]!
                $phoneNumbers: [String!]!
                $addresses: [AddressInput!]!
                $tempEmail: String
                $tempEmailPassword: String
                $department: String
                $age: String
                $otherInfo: String
                $movedToMonitoringAt: String
                $scannedAt: String
                $includeStreetSuffixInQuickSearch: Boolean
                $ClientType: ClientType
                $dateOfBirth: Date
                $spouses: [String!]
                $sendReportsTo: String
                $stripeAccount: String
              ) {
                updateClient(
                  id: $id
                  firstName: $firstName
                  lastName: $lastName
                  middleName: $middleName
                  nickname: $nickname
                  maidenName: $maidenName
                  alternateFirstNames: $alternateFirstNames
                  alternateLastNames: $alternateLastNames
                  email: $email
                  status: $status
                  assignTo: $assignTo
                  addresses: $addresses
                  emails: $emails
                  phoneNumbers: $phoneNumbers
                  tempEmail: $tempEmail
                  tempEmailPassword: $tempEmailPassword
                  age: $age
                  department: $department
                  otherInfo: $otherInfo
                  movedToMonitoringAt: $movedToMonitoringAt
                  scannedAt: $scannedAt
                  includeStreetSuffixInQuickSearch: $includeStreetSuffixInQuickSearch
                  clientType: $ClientType
                  dateOfBirth: $dateOfBirth
                  spouses: $spouses
                  sendReportsTo: $sendReportsTo
                  stripeAccount: $stripeAccount
                ) {
                  ...AllClientData
                  movedToMonitoringAt
                  scannedAt
                  assignedTo {
                    id
                    name
                  }
                }
              }
              ${AllClientData}
            `,
                        variables: {
                            ...this.clientData,
                            assignTo: this.clientData.assignTo.value,
                            email: this.clientData.emails[0],
                            dateOfBirth: this.clientData.dateOfBirth
                                ? new Date(this.clientData.dateOfBirth)
                                : null,
                        },
                        refetchQueries: ['clients', 'client'],
                    })
                    .then(() => {
                        this.loading = false

                        this.$emit('mutationCompleted')
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$q.dialog({
                            title: 'Error',
                            message: err.message.replace('GraphQL error:', '').trim(),
                        })
                    })
            } else {
                this.$apollo
                    .mutate<createClient>({
                        mutation: gql`
              mutation createClient(
                $firstName: String!
                $lastName: String!
                $middleName: String
                $nickname: String
                $maidenName: String
                $alternateFirstNames: [String!]
                $alternateLastNames: [String!]
                $email: String!
                $status: Client_status!
                $assignTo: String!
                $emails: [String!]!
                $phoneNumbers: [String!]!
                $addresses: [AddressInput!]!
                $tempEmail: String
                $tempEmailPassword: String
                $department: String
                $age: String
                $otherInfo: String
                $includeStreetSuffixInQuickSearch: Boolean
                $spouses: [String!]
                $sendReportsTo: String
                $dateOfBirth: Date
                $stripeAccount: String
              ) {
                createClient(
                  firstName: $firstName
                  lastName: $lastName
                  middleName: $middleName
                  nickname: $nickname
                  maidenName: $maidenName
                  alternateFirstNames: $alternateFirstNames
                  alternateLastNames: $alternateLastNames
                  email: $email
                  status: $status
                  assignTo: $assignTo
                  addresses: $addresses
                  emails: $emails
                  phoneNumbers: $phoneNumbers
                  tempEmail: $tempEmail
                  tempEmailPassword: $tempEmailPassword
                  age: $age
                  department: $department
                  otherInfo: $otherInfo
                  includeStreetSuffixInQuickSearch: $includeStreetSuffixInQuickSearch
                  spouses: $spouses
                  sendReportsTo: $sendReportsTo
                  dateOfBirth: $dateOfBirth
                  stripeAccount: $stripeAccount
                ) {
                  ...AllClientData
                  ...StatusCounts
                  assignedTo {
                    id
                    name
                  }
                }
              }
              ${AllClientData}
              ${StatusCounts}
            `,
                        variables: {
                            ...this.clientData,
                            assignTo: this.clientData.assignTo.value,
                            email: this.clientData.emails[0],
                            dateOfBirth: this.clientData.dateOfBirth
                                ? new Date(this.clientData.dateOfBirth)
                                : null,
                        },
                        refetchQueries: ['clients'],
                    })
                    .then(() => {
                        this.loading = false

                        this.$emit('mutationCompleted')

                        this.clientData = {
                            id: '',
                            firstName: '',
                            lastName: '',
                            middleName: '',
                            nickname: '',
                            maidenName: '',
                            alternateFirstNames: [],
                            alternateLastNames: [],
                            tempEmail: '',
                            tempEmailPassword: '',
                            department: '',
                            dateOfBirth: '',
                            age: '',
                            status: 'REMOVAL',
                            otherInfo: '',
                            assignTo: {
                                label: '',
                                value: '',
                            },
                            emails: [''],
                            phoneNumbers: [''],
                            addresses: [
                                {
                                    streetAddress: '',
                                    city: '',
                                    state: '',
                                    zipCode: '',
                                },
                            ],
                            movedToMonitoringAt: '',
                            scannedAt: '',
                            includeStreetSuffixInQuickSearch: false,
                            ClientType: '',
                            spouses: [],
                            sendReportsTo: '',
                            stripeAccount: '',
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$q.dialog({
                            title: 'Error',
                            message: err.message.replace('GraphQL error:', '').trim(),
                        })
                    })
            }
        },
    },

    components: {
        AssignmentSelector,
    },

    props: {
        client: Object,
        inline: Boolean,
        signup: Boolean,
        lockFirstEmail: Boolean,
    },
})
