<template>
  <div class="min-h-screen flex-grow grid grid-cols-1 place-items-center px-2 py-4 lg:p-8">
    <div
      class="px-2 py-4 lg:px-4 lg:py-12 lg:bg-transparent shadow-4 rounded-sm max-w-5xl w-full grid grid-cols-1 lg:grid-cols-2 place-items-center gap-10 lg:gap-24 color-slash"
    >
      <div class="justify-self-center">
        <img width="240" height="240" :src="logoPath" :alt="`${appDisplayName} Logo`" />
      </div>
      <q-form
        class="flex flex-col gap-4 max-w-md lg:justify-self-end"
        style="max-width: 380px"
        @submit="signUp"
      >
        <ClientEditor inline signup ref="clientEditor" :lockFirstEmail="lockFirstEmail" />
        <div class="flex flex-col gap-2">
          <op-input light v-model="address.streetAddress" label="Address" :rules="[required]" />
          <div class="grid grid-cols-7 gap-2">
            <op-input
              light
              class="col-span-3"
              v-model="address.city"
              label="City"
              :rules="[required]"
            />
            <op-input
              light
              class="col-span-2"
              v-model="address.state"
              label="State"
              :rules="[required]"
            />
            <op-input
              light
              class="col-span-2"
              v-model="address.zipCode"
              label="Zip code"
              :rules="[required]"
            />
          </div>
        </div>
        <op-input
          light
          v-model="password"
          label="Password"
          type="password"
          :rules="passwordRules"
        ></op-input>
        <op-input
          light
          class="pb-8"
          v-model="referrer"
          label="How did you hear about us?"
          :rules="[required]"
        ></op-input>
        <q-btn unelevated rounded :loading="signingUp" color="primary" type="submit"
          >Create Account</q-btn
        >
        <div class="text-center mt-4">
          <a
            class="text-blue-900 underline"
            :href="`https://www.${appName}.com/createaccounthelp`"
            target="_blank"
            >Click here if you are having difficulties creating an account</a
          >
        </div>
      </q-form>
    </div>
  </div>
</template>

<script src="./SignUp.ts"></script>

<style scoped>
@media (max-width: 1023px) {
  .color-slash {
    background-image: -webkit-linear-gradient(285deg, #ffffff 20%, var(--q-secondary) 20%);
  }
}
</style>
