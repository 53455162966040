<template>
  <div>
    <div v-if="queryError" class="q-pa-lg error apollo">
      <q-banner rounded class="bg-red-2">
        <template v-slot:avatar> <q-icon name="error" color="red" /> </template>An error occurred
        while trying to fetch users:
        <span class="q-pl-sm">{{ queryError }}</span>
      </q-banner>
    </div>

    <q-dialog v-model="editDialog">
      <q-card class="q-pa-md">
        <q-card-section>
          <div class="text-h6">User Editor</div>
        </q-card-section>

        <q-card-section>
          <user-editor @created="handleCreate"></user-editor>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-table
      class="q-ma-sm"
      title="Users"
      :rows="users"
      :columns="table.columns"
      :visible-columns="showType === 'Only Self Removers' ? ['accountLocked'] : []"
      row-key="name"
      :loading="$apollo.queries.users.loading"
      v-model:pagination="table.pagination"
      :rows-per-page-options="[50, 100, 500, 1000, 0]"
    >
      <template v-slot:top-right>
        <q-select
          v-model="showType"
          :options="['All', 'Only Self Removers', 'Only Users']"
          label="Show Types"
          class="q-pr-md min-w-8"
        />
        <q-btn @click="editDialog = true" color="primary" icon="person_add" outline>
          Create New
        </q-btn>

        <q-input class="q-mx-sm" debounce="300" dense v-model="table.filterInput" label="Search">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>

        <q-btn
          @click="$apollo.queries.users.refetch().catch()"
          :loading="$apollo.queries.users.loading"
          icon="refresh"
          flat
          round
        />
      </template>

      <template v-slot:body="props">
        <q-tr class="cursor-pointer" :props="props">
          <router-link custom v-slot="{ navigate }" :to="`/user/${props.row.id}`">
            <span
              style="display: contents"
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
            >
              <q-td v-for="(columnData, name) of filterColumnData(props.row)" :key="name">
                {{
                  typeof columnData === 'boolean' || columnData === null
                    ? columnData
                      ? 'yes'
                      : 'no'
                    : name === 'authLevel'
                    ? columnData.toLowerCase().replace(/^./, (text) => text.toUpperCase())
                    : columnData
                }}
              </q-td>
            </span>
          </router-link>
        </q-tr>
      </template>
    </q-table>

    <div class="pagination">
      <q-pagination v-model="table.page" :max="table.totalPages" input />
    </div>
  </div>
</template>

<script src="./UserList.ts"></script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 1rem;
}
</style>
