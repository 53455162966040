<template>
  <div>
    <div class="row">
      <div class="col">
        <q-table
          class="q-ma-sm"
          title="Completed Manual Scans"
          :rows="completedScansData"
          :columns="table.columns"
          :pagination="table.pagination"
          row-key="name"
          :loading="$apollo.queries.completedScans.loading"
        >
          <template v-slot:top-right>
            <q-btn
              @click="$apollo.queries.completedScans.refetch().catch()"
              :loading="$apollo.queries.completedScans.loading"
              icon="refresh"
              flat
              round
            />
          </template>
          <template v-slot:body="props">
            <q-tr class="cursor-pointer" :props="props">
              <router-link custom v-slot="{ navigate }" :to="`/client/${props.row.clientId}`">
                <span
                  style="display: contents"
                  @click="navigate"
                  @keypress.enter="navigate"
                  role="link"
                >
                  <q-td v-for="(columnData, name) of filterColumnData(props.row)" :key="name">
                    {{ columnData }}
                  </q-td>
                </span>
              </router-link>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script src="./CompletedScanList.ts"></script>
