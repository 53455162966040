import { siteCategoriesQuery } from '@/queries'
import {
    displayStatuses,
    formatForDisplay,
    hasAuthLevel,
    sortWithDate,
    User_authLevel,
} from '@/utils'
import type { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import type { RemovalStatus } from 'schema-types/globalTypes'
import type {
    siteStatusesAndSites_sites,
    siteStatusesAndSites_siteStatuses,
} from 'schema-types/siteStatusesAndSites'
import { defineComponent } from 'vue'

import SiteStatusSelector from '@/components/SiteStatusSelector/SiteStatusSelector.vue'

import client from '@/views/Client/Client'

export default defineComponent({
    name: 'SiteStatusesTable',

    data() {
        return {
            siteStatusSelector: { label: '', value: '' },
            filter: '',
            filterCategory: 'all',
            appName: process.env.VUE_APP_NAME,
            manualChecksOnly: false,
            isUser: false,
            isPremiumUser: false,
            quickScanLoading: false,
            deepScanLoading: false,
            cancelDeepScanLoading: false,
            siteForDeepScan: [{ id: null, name: 'All sites' }] as { id: string | null; name: string }[],
            quickScanColors: {} as { [key: string]: string },
            siteStatusesAndSites: {
                siteStatuses: [] as siteStatusesAndSites_siteStatuses[],
                sites: [] as siteStatusesAndSites_sites[],
                client: {} as { deepScanStatus: string; deepScanStatusCreatedAt: Date },
            },
            columns: [
                {
                    name: 'site',
                    label: 'Site name',
                    align: 'left',
                    field: 'site',
                    sortable: true,
                },
                {
                    name: 'status',
                    align: 'left',
                    label: 'Status',
                    field: 'status',
                    sortable: true,
                },
                {
                    name: 'updatedAt',
                    align: 'left',
                    label: 'Updated At',
                    field: 'updatedAt',
                    sortable: true,
                },
                {
                    name: 'deepScan',
                    align: 'left',
                    label: 'Deep Scan',
                    field: 'deepScan',
                    sortable: false,
                    headerStyle: 'width: 100px; text-align: center;',
                },
            ] as {
                name: string
                align: string
                label: string
                field: string
                sortable: boolean
                headerStyle?: string
            }[],
            error: false as boolean | ApolloError,
            pagination: {
                sortBy: 'site',
                descending: false,
                page: 1,
                rowsPerPage: 0,
            },
            deepScanStatus: '',
            deepScanStatusCreatedAt: Date.now(),
            time: '0',
            interval: null as NodeJS.Timer | null,
        }
    },

    apollo: {
        siteStatusesAndSites: {
            query: gql`
        query siteStatusesAndSites(
          $clientId: String!
          $manualChecksOnly: Boolean
          $category: String
        ) {
          siteStatuses(clientId: $clientId, manualChecksOnly: $manualChecksOnly) {
            id
            site
            status
            updatedAt
            deepScanStatus
          }
          sites(category: $category) {
            deepScanEnabled
            name
            includeInCseSearch
            id
          }
          client(id: $clientId) {
            deepScanStatus
            deepScanStatusCreatedAt
          }
        }
      `,
            variables() {
                return {
                    clientId: this.$route.params.clientId,
                    manualChecksOnly: (this as any).manualChecksOnly,
                    category:
                        (this as any).filterCategory === 'all' ? undefined : (this as any).filterCategory,
                }
            },
            skip() {
                return !this.$route.params.clientId
            },
            update: (data) => data,
            error(error) {
                this.error = error
            },
        },

        client: {
            query: gql`
        query clientStatus($id: String!) {
          client(id: $id) {
            id
            status
            ClientType
          }
        }
      `,
            skip() {
                return !this.$route.params.clientId
            },
            variables() {
                return {
                    id: this.$route.params.clientId,
                }
            },
        },
        siteCategories: siteCategoriesQuery,
    },

    computed: {
        client() {
            return client
        },

        siteStatuses() {
            const { siteStatuses, sites } = this.siteStatusesAndSites
            const data: Array<{
                site: string
                updatedAt: string
                status: string
                siteId: string
                deepScan: string | null | boolean
            }> = []

            if (siteStatuses && sites) {
                siteStatuses.forEach(({ site, status, updatedAt, deepScanStatus }) => {
                    if (sites.find(({ id }) => id === site)) {
                        data.push({
                            site: (sites.find(({ id }) => id === site) || { name: site }).name,
                            siteId: site,
                            status,
                            updatedAt: formatForDisplay(updatedAt, 'updatedAt') || '',
                            deepScan: sites.find(({ id }) => id === site)?.deepScanEnabled && this.isUser && this.client?.status !== 'SELFREMOVAL'
                                ? deepScanStatus
                                : null,
                        })
                    }
                })

                if (!this.manualChecksOnly) {
                    sites.forEach(({ id, name }) => {
                        if (!siteStatuses.find(({ site }) => site === id)) {
                            data.push({
                                site: name,
                                siteId: id,
                                status: 'UNKNOWN',
                                updatedAt: 'Never',
                                deepScan: null,
                            })
                        }
                    })
                }
            }
            return data
        },
    },

    updated() {
        if (this.siteStatusesAndSites.client.deepScanStatus === 'IN_PROGRESS' && !this.interval) {
            this.interval = setInterval(this.incrementTime, 1000)
        } else if (this.siteStatusesAndSites.client.deepScanStatus !== 'IN_PROGRESS' && this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    },

    mounted() {
        this.$apollo.queries.siteStatusesAndSites.refetch().finally()

        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })

        hasAuthLevel(this.$apollo, User_authLevel.SELFREMOVAL).then((isPremiumUser) => {
            this.isPremiumUser = isPremiumUser
        })

        if (this.siteStatusesAndSites.client.deepScanStatus === 'IN_PROGRESS') {
            this.interval = setInterval(this.incrementTime, 1000)
        }
    },

    methods: {
        selectSiteForDeepScan(val: { id: string | null; name: string }[]) {
            if (val.length > 1 && val.find((el) => el.id === 'all')) {
                return (this.siteForDeepScan = [{ id: null, name: 'All sites' }])
            }

            if (val.length === 0) {
                return (this.siteForDeepScan = [{ id: null, name: 'All sites' }])
            }

            this.siteForDeepScan = val.filter((el) => el.id)
        },

        async quickScan() {
            this.quickScanLoading = true

            const foundSites = await this.$apollo
                .query({
                    query: gql`
            query quickScan($clientId: String!) {
              cseSearch(clientId: $clientId) {
                id
              }
            }
          `,
                    variables: {
                        clientId: this.$route.params.clientId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .catch((err) => {
                    this.quickScanLoading = false
                    this.$q.dialog({
                        title: 'Error',
                        message: err.message.replace('GraphQL error:', '').trim(),
                    })
                })

            this.siteStatusesAndSites.sites.forEach((site) => {
                if (!site.includeInCseSearch) {
                    this.quickScanColors[site.id] = 'grey'
                } else if (foundSites?.data.cseSearch.some(({ id }: { id: string }) => id === site.id)) {
                    this.quickScanColors[site.id] = 'red'
                } else {
                    this.quickScanColors[site.id] = 'green'
                }
            })
            this.quickScanLoading = false
        },

        formatForDisplay(data: any, name: string) {
            if (['to', 'from', 'status'].includes(name) && typeof data === 'string') {
                if (data.toLowerCase() === 'unknown') {
                    return 'Pending'
                }
                return displayStatuses[data as RemovalStatus] || data
            }
            return formatForDisplay(data, name)
        },

        sortWithDate,

        filterColumnData(columnData: { [index: string]: string }) {
            const exclude = ['__index', 'siteId']
            return Object.keys(columnData)
                .filter((key) => !exclude.includes(key))
                .reduce((obj: { [index: string]: string }, key) => {
                    obj[key] = columnData[key]
                    return obj
                }, {})
        },

        async saveSiteStatus(siteId: string) {
            await this.$apollo.mutate({
                mutation: gql`
          mutation setSiteStatus($status: RemovalStatus!, $clientId: String!, $siteId: String!) {
            setStatus(clientId: $clientId, status: $status, siteId: $siteId) {
              id
              from
              to
              createdAt
              siteId
            }
          }
        `,
                variables: {
                    status: this.siteStatusSelector.value,
                    clientId: this.$route.params.clientId,
                    siteId,
                },
            })

            this.$apollo.queries.siteStatusesAndSites.refetch().finally()
        },

        formattedTime() {
            const createdAt = new Date(this.siteStatusesAndSites.client.deepScanStatusCreatedAt)
            const now = new Date()
            const diff = now.getTime() - createdAt.getTime()

            // Calculate hours, minutes, and seconds
            const hours = Math.floor(diff / (1000 * 60 * 60))
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((diff % (1000 * 60)) / 1000)

            // Format the result
            const formattedDiff = `${hours}h ${minutes}m ${seconds}s`

            return formattedDiff
        },

        incrementTime() {
            if (this.siteStatusesAndSites.client.deepScanStatus !== 'IN_PROGRESS' && this.interval) {
                clearInterval(this.interval)
            } else {
                this.time = this.formattedTime()
            }
        },

        async cancelDeepScan() {
            this.cancelDeepScanLoading = true

            await this.$apollo
                .mutate({
                    mutation: gql`
            mutation cancelDeepScan($clientId: String!) {
              cancelDeepScan(clientId: $clientId)
            }
          `,
                    variables: {
                        clientId: this.$route.params.clientId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(() => {
                    localStorage.removeItem('deep-scan-status')
                    this.$apollo.queries.siteStatusesAndSites.refetch().finally()

                    this.$q.dialog({
                        title: 'Deep Scan',
                        message: 'Deep Scan has been canceled.',
                    })
                })
                .catch((err) => {
                    this.cancelDeepScanLoading = false
                    this.$q.dialog({
                        title: 'Error canceling eep scan',
                        message: err.message.replace('GraphQL error:', '').trim(),
                    })
                })

            this.cancelDeepScanLoading = false
        },

        async deepScan() {
            this.deepScanLoading = true
            this.interval = setInterval(this.incrementTime, 1000)

            await this.$apollo
                .query({
                    query: gql`
            query deepScan($clientId: String!, $siteIds: [String!]) {
              deepScan(clientId: $clientId, siteIds: $siteIds) {
                id
                message
                searchStatus
              }
            }
          `,
                    variables: {
                        clientId: this.$route.params.clientId,
                        siteIds: this.siteForDeepScan?.filter((el) => el.id)?.length
                            ? this.siteForDeepScan?.filter((el) => el.id).map((el) => el.id)
                            : undefined,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(({ data }) => {
                    this.$apollo.queries.siteStatusesAndSites.refetch().finally()
                    if (data.deepScan.searchStatus === 'IN_PROGRESS') {
                        localStorage.setItem('deep-scan-status', `${data.deepScan.id}`)

                        this.$q.dialog({
                            title: 'Deep scan',
                            message: 'Deep scan In Progress... We will let you know when the process is complete',
                        })

                        this.siteForDeepScan = [{ id: null, name: 'All sites' }]
                    }
                })
                .catch((err) => {
                    this.deepScanLoading = false
                    this.$q.dialog({
                        title: 'Error deep scan',
                        message: err.message.replace('GraphQL error:', '').trim(),
                    })
                })

            this.deepScanLoading = false
        },
    },

    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    },

    components: {
        SiteStatusSelector,
    },
})
