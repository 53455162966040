<template>
  <div class="row justify-around q-mt-lg">
    <q-card class="col q-gutter-sm q-pa-md" :flat="$q.screen.xs" style="max-width: 400px">
      <div class="row justify-center">
        <q-icon size="80px" color="primary" name="account_circle" />
      </div>
      <div class="row justify-center text-h4">Change Password</div>
      <q-form @submit="changePassword">
        <q-input
          class="row col-xs-12"
          v-model="passwordInput"
          label="Password"
          type="password"
          :rules="passwordRules"
        ></q-input>
        <div class="row justify-center q-pt-sm">
          <q-btn :loading="changingPassword" color="primary" type="submit">Change Password</q-btn>
        </div>
      </q-form>
    </q-card>
  </div>
</template>

<script src="./ChangePassword.ts"></script>
