<template>
  <q-select
    filled
    use-input
    :modelValue="modelValue"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    input-debounce="250"
    label="Site Status"
    :options="siteStatuses"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">No results</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script src="./SiteStatusSelector.ts"></script>
