import {
  displayStatuses,
  formatForDisplay,
  hasAuthLevel,
  openSiteUrl,
  sortWithDate,
  User_authLevel,
} from '@/utils'
import gql from 'graphql-tag'
import type { RemovalStatus } from 'schema-types/globalTypes'
import type { history_history } from 'schema-types/history'
import type { siteNote_siteNote } from 'schema-types/siteNote'
import { defineComponent } from 'vue'
import type { ApolloError } from '@apollo/client'
import { deepScanDetails as deepScanDetailsType } from 'schema-types/deepScanDetails'
import client from '@/views/Client/Client'
import { currentUserQuery } from '@/queries'

const historyQuery = gql`
  query historyForDeepScan($clientId: String!, $siteId: String!) {
    history(clientId: $clientId, siteId: $siteId) {
      id
      createdAt
      from
      to
    }
  }
`
const deepScanDetails = gql`
  query deepScanDetails($clientId: String!, $siteId: String!) {
    deepScanDetails(clientId: $clientId, siteId: $siteId) {
      data {
        id
        client {
          firstName
          lastName
          middleName
        }
        site {
          id
          name
          url
        }
        claimedBy {
          id
          name
        }
        matchedData {
          url
          matchedBy {
            field
            value
            values
          }
        }
        siteStatus
        createdAt
        blockedPages {
          url
          reason
        }
      }
      lastCreatedDeepScanAt
    }
  }
`

export default defineComponent({
  name: 'DeepScanResults',

  data() {
    return {
      deepScanDetails: {} as deepScanDetailsType['deepScanDetails']['data'][0],
      deepScanDetailsError: undefined as ApolloError | undefined,
      selectedStatus: 'EXIST',
      siteDeepScanResults: {} as siteNote_siteNote,
      lastDeepScanDate: '' as string,
      history: [] as history_history[],
      error: false as boolean | ApolloError,
      columns: [
        {
          name: 'createdAt',
          required: true,
          label: 'Date',
          align: 'left',
          field: 'createdAt',
          sortable: true,
        },
        {
          name: 'from',
          align: 'left',
          label: 'From',
          field: 'from',
          sortable: true,
        },
        {
          name: 'to',
          align: 'left',
          label: 'To',
          field: 'to',
          sortable: true,
        },
      ],
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      updateStatusDialog: false,
      isUser: false,
      isAdmin: false,
      claimSiteLoading: false,
      statusLoading: false,
    }
  },

  apollo: {
    deepScanDetails: {
      query: deepScanDetails,
      skip() {
        return !this.$route.params.siteId
      },
      variables() {
        return {
          siteId: this.$route.params.siteId,
          clientId: this.$route.params.clientId,
        }
      },
      result({ data }: { data: deepScanDetailsType }) {
        this.deepScanDetailsError = undefined
        this.deepScanDetails = data.deepScanDetails.data[0]
        this.lastDeepScanDate = data.deepScanDetails.lastCreatedDeepScanAt
      },
      error(error) {
        this.deepScanDetailsError = error
      },
    },

    history: {
      query: historyQuery,
      variables() {
        return {
          clientId: this.$route.params.clientId,
          siteId: this.$route.params.siteId,
        }
      },
      skip() {
        return !this.$route.params.siteId || !this.$route.params.clientId
      },
      error(err) {
        this.error = err
      },
      result({ data }) {
        if (data) {
          this.error = false

          const historyData = data.history
          if (historyData.length > 0) {
            const latestStatus = historyData[historyData.length - 1].to
            if (latestStatus) {
              this.selectedStatus = latestStatus
            }
          }
        }
      },
    },

    currentUser: {
      query: currentUserQuery,
    },
  },

  computed: {
    client() {
      return client
    },

    statusOptions(): Array<{ value: string; label: string }> {
      const useStatuses = Object.keys(displayStatuses).filter(
        (status) => this.isUser || status !== 'ADDEDANDREMOVED',
      )

      return useStatuses.map((status) => {
        let isCurrent
        if (this.history.length > 0) {
          if (this.history[this.history.length - 1].to === status) {
            isCurrent = true
          }
        }
        return {
          value: status,
          label:
            (displayStatuses as { [index: string]: string })[status] +
            (isCurrent ? ' (current)' : ''),
        }
      })
    },
  },

  methods: {
    async claimSite() {
      this.claimSiteLoading = true

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation claimSiteDeepScan(
              $clientId: String!
              $siteId: String!
              $action: CheckOutSiteAction!
            ) {
              checkOutSite(payload: { clientId: $clientId, siteId: $siteId, action: $action }) {
                id
              }
            }
          `,
          variables: {
            siteId: this.$route.params.siteId,
            clientId: this.$route.params.clientId,
            action: 'CLAIM',
          },
        })
        .then(() => {
          this.$apollo.queries.deepScanDetails.refetch().finally()

          this.$q.dialog({
            title: 'Claimed',
            message: 'You claimed site',
          })
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error claim site',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })

      this.claimSiteLoading = false
    },

    async unClaimSite() {
      this.claimSiteLoading = true

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation unClaimSiteDeepScan(
              $clientId: String!
              $siteId: String!
              $action: CheckOutSiteAction!
            ) {
              checkOutSite(payload: { clientId: $clientId, siteId: $siteId, action: $action }) {
                id
              }
            }
          `,
          variables: {
            siteId: this.$route.params.siteId,
            clientId: this.$route.params.clientId,
            action: 'UNCLAIM',
          },
        })
        .then(() => {
          this.$apollo.queries.deepScanDetails.refetch().finally()

          this.$q.dialog({
            title: 'Un-claimed',
            message: 'You un-claimed site',
          })
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error un-claim site',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })

      this.claimSiteLoading = false
    },

    async setStatusMutation() {
      this.statusLoading = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation setStatusDeepScan(
              $status: RemovalStatus!
              $clientId: String!
              $siteId: String!
            ) {
              setStatus(clientId: $clientId, status: $status, siteId: $siteId) {
                id
                from
                to
                createdAt
                siteId
              }
            }
          `,
          variables: {
            siteId: this.$route.params.siteId,
            clientId: this.$route.params.clientId,
            status: this.selectedStatus,
          },
        })
        .then(() => {
          this.$apollo.queries.deepScanDetails.refetch()
          this.$apollo.queries.history.refetch()
          this.updateStatusDialog = false
          this.$q.dialog({
            title: 'Status',
            message: 'Status changed.',
          })
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error status',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })

      this.statusLoading = false
    },

    formatForDisplay(data: any, name: string) {
      if (['to', 'from'].includes(name) && typeof data === 'string') {
        return displayStatuses[data as RemovalStatus]
      }
      return formatForDisplay(data, name)
    },

    openSiteUrl,
    sortWithDate,

    filterColumnData(columnData: { [index: string]: string }) {
      const columnOrder = this.columns.map(({ field }) => field)
      const exclude = ['__index', 'id', '__typename']

      return Object.keys(columnData)
        .filter((key) => !exclude.includes(key))
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .reduce((obj: { [index: string]: string }, key) => {
          obj[key] = columnData[key]
          return obj
        }, {})
    },
  },

  mounted() {
    this.$apollo.queries.deepScanDetails.refetch().finally()
    this.$apollo.queries.history.refetch().finally()

    hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
      this.isUser = isUser
    })

    hasAuthLevel(this.$apollo, User_authLevel.ADMIN).then((isAdmin) => {
      this.isAdmin = isAdmin
    })
  },
})
