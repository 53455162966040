import ClientEditor from '@/components/ClientEditor/ClientEditor.vue'
import { currentUserQuery } from '@/queries'
import { passwordRules, trackConversion } from '@/utils'
import gql from 'graphql-tag'
import type { signUp } from 'schema-types/signUp'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SignUp',
    data() {
        return {
            address: {
                city: '',
                state: '',
                zipCode: '',
                streetAddress: '',
            },
            password: '',
            referrer: '',
            signingUp: false,
            passwordRules,
            appName: process.env.VUE_APP_NAME,
            appDisplayName: process.env.VUE_APP_DISPLAYNAME,
            logoPath: require(`@/assets/logo-${process.env.VUE_APP_NAME}.png`),
            required: (val?: string) => (val && val.length > 0) || 'This field is required',
            lockFirstEmail: false,
        }
    },
    mounted() {
        // Autofill email address from Moonclerk customer ID
        if (this.$route.query.moonclerkCustomerId) {
            this.$apollo
                .query({
                    query: gql`
            query emailFromMoonclerkCustomerId($moonclerkCustomerId: String!) {
              emailFromMoonclerkCustomerId(moonclerkCustomerId: $moonclerkCustomerId)
            }
          `,
                    variables: {
                        moonclerkCustomerId: this.$route.query.moonclerkCustomerId,
                    },
                })
                .then(({ data }) => {
                    if (data.emailFromMoonclerkCustomerId) {
                        ; (this.$refs.clientEditor as any).clientData.emails[0] =
                            data.emailFromMoonclerkCustomerId
                        this.lockFirstEmail = true
                    }
                })
        }
    },
    methods: {
        signUp() {
            this.signingUp = true
            const clientData = (this.$refs.clientEditor as any).clientData
            this.$apollo
                .mutate<signUp>({
                    mutation: gql`
            mutation signUp(
              $email: String!
              $password: String!
              $firstName: String!
              $lastName: String!
              $middleName: String
              $nickname: String
              $maidenName: String
              $alternateFirstNames: [String!]
              $alternateLastNames: [String!]
              $emails: [String!]!
              $phoneNumbers: [String!]!
              $addresses: [AddressInput!]!
              $moonclerkCustomerId: String
              $referrer: String
            ) {
              signUp(
                email: $email
                password: $password
                firstName: $firstName
                lastName: $lastName
                middleName: $middleName
                nickname: $nickname
                maidenName: $maidenName
                alternateFirstNames: $alternateFirstNames
                alternateLastNames: $alternateLastNames
                addresses: $addresses
                emails: $emails
                phoneNumbers: $phoneNumbers
                moonclerkCustomerId: $moonclerkCustomerId
                referrer: $referrer
              ) {
                token
                user {
                  id
                  name
                  email
                  authLevel
                }
              }
            }
          `,
                    variables: {
                        email: clientData.emails[0],
                        firstName: clientData.firstName,
                        lastName: clientData.lastName,
                        middleName: clientData.middleName,
                        nickname: clientData.nickname,
                        maidenName: clientData.maidenName,
                        alternateFirstNames: clientData.alternateFirstNames,
                        alternateLastNames: clientData.alternateLastNames,
                        password: this.password,
                        emails: clientData.emails,
                        phoneNumbers: clientData.phoneNumbers,
                        addresses: [this.address],
                        moonclerkCustomerId: this.$route.query.moonclerkCustomerId,
                        referrer: this.referrer,
                    },
                    update: (cache, { data }) => {
                        if (data) {
                            const { user, token } = data.signUp
                            cache.writeQuery({
                                query: currentUserQuery,
                                data: { currentUser: { token, ...user } },
                            })
                        }
                    },
                })
                .then(async ({ data }) => {
                    // Facebook tracking code
                    await import('@/utils/facebookTracking.js')
                    // Google ad conversion tracking
                    trackConversion(data?.signUp.user.id)

                    if (data) {
                        const user = data.signUp.user
                        this.signingUp = false
                        localStorage.setItem('userEmail', data.signUp.user.email)
                        localStorage.setItem('showTutorialDialog', 'true')
                        this.$q.notify(`Logged in as ${user.name}.`)
                        this.$router.push('/clients')
                    }
                })
                .catch((err: Error) => {
                    this.signingUp = false
                    this.$q.dialog({
                        title: 'Error',
                        message: err.message.replace('GraphQL error:', '').trim(),
                    })
                    if (!err.message.includes('GraphQL error:')) {
                        // For Rollbar to catch if the error isn't expected
                        // ie, no payment associated with email
                        throw err
                    }
                })
        },
    },
    components: {
        ClientEditor,
    },
})
