import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import { passwordRules } from '@/utils'

export default defineComponent({
  name: 'ChangePassword',
  data() {
    return {
      passwordInput: '',
      changingPassword: false,
      passwordRules,
    }
  },
  methods: {
    changePassword() {
      this.changingPassword = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation changePassword($password: String!, $token: String!) {
              changePassword(password: $password, token: $token) {
                id
              }
            }
          `,
          variables: {
            password: this.passwordInput,
            token: this.$route.query.token,
          },
        })
        .then(() => {
          this.changingPassword = false
          this.$q
            .dialog({
              title: 'Password Changed Successfully',
              message: 'Please go to the login page to sign in again.',
              persistent: true,
              ok: {
                label: 'Go To Login',
              },
            })
            .onOk(() => {
              this.$router.push('/login')
            })
        })
        .catch((err: Error) => {
          this.changingPassword = false
          this.$q.dialog({
            title: 'Error',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })
    },
  },
})
