<template>
  <div>
    <client-list manual-checks-only></client-list>
    <completed-scan-list></completed-scan-list>
  </div>
</template>

<script src="./Monitoring.ts"></script>

<style></style>
