import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import qrcode from 'qrcode'
import type { getTwoFactorCode } from 'schema-types/getTwoFactorCode'
import type { ApolloQueryResult } from '@apollo/client'

export default defineComponent({
  name: 'TwoFactorEnable',
  data() {
    return {
      step: 1,
      qrCode: '',
      totpCode: '',
      getTwoFactorCodeMutation: gql`
        mutation getTwoFactorCode {
          getTwoFactorCode {
            otpauthUrl
          }
        }
      `,
      verifyTwoFactorMutation: gql`
        mutation verifyTwoFactor($token: String!) {
          verifyTwoFactor(token: $token) {
            id
            twoFactorEnabled
          }
        }
      `,
    }
  },
  props: {
    preventCancel: Boolean,
  },
  methods: {
    showQrCode({ data }: ApolloQueryResult<getTwoFactorCode>) {
      qrcode.toDataURL(data.getTwoFactorCode.otpauthUrl).then((url) => {
        this.qrCode = url
      })
    },
  },
})
