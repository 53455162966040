import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import type { completedScans_completedScans } from 'schema-types/completedScans'
import { formatForDisplay } from '@/utils'

export default defineComponent({
  name: 'CompletedScanList',
  data() {
    return {
      completedScans: [],
      table: {
        columns: [
          {
            name: 'clientName',
            label: 'Client Name',
            align: 'left',
            field: 'clientName',
            sortable: false,
          },
          {
            name: 'clientEmail',
            align: 'left',
            label: 'Email',
            field: 'clientEmail',
            sortable: false,
          },
          {
            name: 'scannedBy',
            align: 'left',
            label: 'Scanned By',
            field: 'scannedBy',
            sortable: false,
          },
          {
            name: 'startedAt',
            align: 'left',
            label: 'Scan Started',
            field: 'startedAt',
            sortable: false,
          },
          {
            name: 'completedAt',
            align: 'left',
            label: 'Scan Completed',
            field: 'completedAt',
            sortable: false,
          },
        ],
        pagination: {
          page: 1,
          rowsPerPage: 0,
        },
      },
    }
  },
  apollo: {
    completedScans: {
      query: gql`
        query completedScans {
          completedScans {
            id
            startedAt
            completedAt
            client {
              id
              email
              firstName
              lastName
            }
            scannedBy {
              id
              name
            }
          }
        }
      `,
      error(e) {
        this.$q
          .dialog({
            title: 'Error loading completed scans',
            message: e.message.replace('GraphQL error: ', ''),
            ok: {
              label: 'Retry',
            },
            persistent: true,
          })
          .onOk(() => {
            this.$apollo.queries.completedScans.refetch()
          })
      },
    },
  },
  computed: {
    completedScansData() {
      const completedScans: completedScans_completedScans[] = this.completedScans
      if (completedScans) {
        return completedScans.map(({ startedAt, completedAt, client, scannedBy }) => {
          return {
            clientId: client?.id,
            clientName: `${client?.firstName} ${client?.lastName}`,
            clientEmail: client?.email,
            scannedBy: scannedBy?.name,
            startedAt: formatForDisplay(startedAt, 'startedAt'),
            completedAt: formatForDisplay(completedAt, 'completedAt'),
          }
        })
      }
      return []
    },
  },
  methods: {
    filterColumnData(columnData: { [index: string]: string }) {
      const columnOrder = this.table.columns.map(({ field }) => field)
      const exclude = ['__index', 'clientId']
      return Object.keys(columnData)
        .filter((key) => !exclude.includes(key))
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .reduce((obj: { [index: string]: string }, key) => {
          obj[key] = columnData[key]
          return obj
        }, {})
    },
  },
})
