import UserEditor from '@/components/UserEditor/UserEditor.vue'
import { defineComponent } from 'vue'
import { usersQuery } from '@/queries'
import { ApolloError } from '@apollo/client'
import { localStoreFilters } from '@/utils'

export default defineComponent({
  name: 'UserList',

  data() {
    const filters = localStoreFilters.getItem('users-filter') as
      | {
          showType: string
          sortBy: string
          descending: boolean
          rowsPerPage: number
        }
      | undefined

    return {
      showType: filters?.showType || 'Only Users',
      queryError: false as ApolloError | boolean,
      editDialog: false,
      users: [],
      table: {
        filterInput: '',
        page: 1,
        totalPages: 1,
        columns: [
          {
            name: 'name',
            required: true,
            label: 'Name',
            align: 'left',
            field: 'name',
            sortable: true,
          },
          {
            name: 'email',
            required: true,
            align: 'left',
            label: 'Email',
            field: 'email',
            sortable: true,
          },
          {
            name: 'authLevel',
            required: true,
            align: 'left',
            label: 'Auth Level',
            field: 'authLevel',
            sortable: true,
          },
          {
            name: 'twoFactorEnabled',
            required: true,
            align: 'left',
            label: 'Two Factor Enabled',
            field: 'twoFactorEnabled',
            sortable: true,
          },
          {
            name: 'accountLocked',
            align: 'left',
            label: 'Account Locked',
            field: 'accountLocked',
            sortable: true,
          },
        ],
        pagination: {
          sortBy: filters?.sortBy || 'name',
          descending: filters?.descending || false,
          rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
        },
      },
    }
  },

  apollo: {
    users: {
      query: usersQuery,
      variables() {
        return {
          page: this.table.page,
          perPage: this.table.pagination.rowsPerPage || 1000 * 1000,
          search: this.table.filterInput.trimStart(),
          onlySelfRemoval: this.showType === 'Only Self Removers',
          all: this.showType === 'All',
        }
      },
      result({ data }) {
        if (data) {
          this.queryError = false

          this.table.totalPages = data.users.totalPages

          this.users = data.users.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            email: item.email,
            authLevel: item.authLevel,
            twoFactorEnabled: item.twoFactorEnabled,
            accountLocked: item.accountLocked,
          }))
        }
      },
      error(err) {
        this.queryError = err
      },
    },
  },

  watch: {
    showType: {
      handler() {
        this.saveFilters()
      },
    },
    'table.pagination': {
      handler() {
        this.saveFilters()
      },
    },
  },

  methods: {
    saveFilters() {
      localStoreFilters.setItem('users-filter', {
        showType: this.showType,
        sortBy: this.table.pagination.sortBy,
        descending: this.table.pagination.descending,
        rowsPerPage: this.table.pagination.rowsPerPage,
      })
    },

    filterColumnData(columnData: { [index: string]: string }) {
      const exclude = [
        '__index',
        'id',
        '__typename',
        'twoFactorRequired',
        this.showType !== 'Only Self Removers' ? 'accountLocked' : undefined,
      ]

      const columnOrder = this.table.columns.map(({ field }) => field)

      return Object.keys(columnData)
        .filter((key) => !exclude.includes(key))
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .reduce((obj: { [index: string]: string }, key) => {
          obj[key] = columnData[key]
          return obj
        }, {})
    },

    handleCreate() {
      this.editDialog = false
    },
  },

  components: {
    UserEditor,
  },
})
